import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { GridContainer, GridItem } from '@entur/grid';
import { ExternalIcon } from '@entur/icons';
import { Link } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import IllustrationList from '~/components/IllustrationList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageHeader mdxType="PageHeader" />
    <p>{`Motivene i illustrasjonene viser ulike situasjoner og møter i trafikkbildet som er en representasjon av Enturs rolle i kollektiv-Norge. Illustrasjonsstilen
tar utgangspunkt i norsk natur og landskap, og tar opp elementer fra Entur sin visuelle profil for å skape gjenkjennelse og en rød tråd i identiteten.
Illustrasjonenes fremste oppgave er å myke opp og menneskeliggjøre uttrykket i våre digitale flater.`}</p>
    <p>{`Ved behov for andre formater eller utsnitt av materiale som ligger i illustrasjonsbiblioteket, kontakt oss på Slack i `}<a parentName="p" {...{
        "href": "https://entur.slack.com/archives/C899QSPB7"
      }}>{`#talk-designsystem`}</a>{`.
Vi jobber også med å utvikle animasjoner av noen av motivene, etter smidige prinsipper, litt etter litt. Det er foreløpig ikke avklart om de animerte
illustrasjonene skal legges ut her eller et annet sted.`}</p>
    <h2 {...{
      "id": "filformater"
    }}>{`Filformater`}</h2>
    <p>{`Alle illustrasjonene er tilgjengelig i png- og svg-format. På denne siden vil du finne png-versjonen ved å klikke på «Last ned illustrasjon»-knappen på
bildet. Trenger du svg-formatet er det foreløpig kun tilgjengelig via `}<a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/1Y85Rs4FvhhEt02kUd-U_ybzmEHJ_1tHi?usp=sharing"
      }}>{`Google Drive`}</a>{`.`}</p>
    <h2 {...{
      "id": "hovedillustrasjon"
    }}>{`Hovedillustrasjon`}</h2>
    <p>{`Hovedillustrasjonen skal anvendes slik at den oppleves som brukerens første møte med Entur, for å introdusere hvem vi er og hva vi gjør. Brukes f.eks på
introsider eller som onboarding-illustrasjon.`}</p>
    <IllustrationList categoryFilter="landscape" squareIllustrations={false} includeListFile={["city"]} disableColorModeSelector backgroundSwitchLabel="Med ramme" mdxType="IllustrationList" />
    <h2 {...{
      "id": "landskapsmotiv"
    }}>{`Landskapsmotiv`}</h2>
    <p>{`Disse illustrasjonene er spesielt tiltenkt landningssider på web. Illustrasjonene bidrar med å fremheve, visualisere og myke opp innholdet på en ellers
teksttung side. Bildene skal helst dekke hele nettsidens fulle bredde. Ved andre formater (f.eks. i to-kolonners grid) skal det benyttes mindre utsnitt
av landskapet.`}</p>
    <IllustrationList categoryFilter="landscape" squareIllustrations={false} excludeListFile={["city"]} disableColorModeSelector backgroundSwitchLabel="Med ramme" mdxType="IllustrationList" />
    <h2 {...{
      "id": "kvadratiske-motiver"
    }}>{`Kvadratiske motiver`}</h2>
    <p>{`De små illustrasjonene brukes primært på informasjonssider, modaler, infomeldinger, feilmeldinger og sider hvor det mangler innhold («empty states»).`}</p>
    <p>{`Disse motivene er designet for å hyggeliggjøre meldinger som kan være kjedelig eller irriterende for brukeren å få.
Det skal ikke brukes mer enn tre slike illustrasjoner på én sidevisning, og i tilfeller hvor det brukes mer enn én illustrason på en side
bør de settes i et fler-kolonners grid.`}</p>
    <h3 {...{
      "id": "mennesker"
    }}>{`Mennesker`}</h3>
    <IllustrationList categoryFilter="people" disableBackgroundSwitch mdxType="IllustrationList" />
    <IllustrationList categoryFilter="entur staff" disableBackgroundSwitch mdxType="IllustrationList" />
    <h3 {...{
      "id": "dyremotiv"
    }}>{`Dyremotiv`}</h3>
    <IllustrationList categoryFilter="animals" mdxType="IllustrationList" />
    <h3 {...{
      "id": "gjenstander"
    }}>{`Gjenstander`}</h3>
    <IllustrationList categoryFilter="objects" mdxType="IllustrationList" />
    <h3 {...{
      "id": "hånd"
    }}>{`Hånd`}</h3>
    <IllustrationList categoryFilter="hands" mdxType="IllustrationList" />
    <h3 {...{
      "id": "digitale-enheter"
    }}>{`Digitale enheter`}</h3>
    <IllustrationList categoryFilter="devices" disableBackgroundSwitch mdxType="IllustrationList" />
    <h3 {...{
      "id": "transportmidler"
    }}>{`Transportmidler`}</h3>
    <IllustrationList categoryFilter="transport" disableBackgroundSwitch mdxType="IllustrationList" />
    <h3 {...{
      "id": "hittegods"
    }}>{`Hittegods`}</h3>
    <IllustrationList categoryFilter="travel" disableBackgroundSwitch mdxType="IllustrationList" />
    <h3 {...{
      "id": "scenarioer"
    }}>{`Scenarioer`}</h3>
    <IllustrationList categoryFilter="scenario" disableBackgroundSwitch mdxType="IllustrationList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      